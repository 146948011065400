import React, { useState } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import {
  Track,
  Progress,
  Behind,
  Exceeds,
} from "resources/constants/strings.js";
import { useSetState } from "store/store";
import { useHistory } from "react-router-dom";
import { COMMON_CARD_STYLE } from "utils/style";
import ProgressBar from "components/progress";
import { Accordion, Panel } from "baseui/accordion";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_PADDING,
} from "utils/style";
import PhaseBadge from "./badges/phaseBadge";
import MicroBadge from "./badges/microBadge";
import MacroBadge from "./badges/macroBadge";
import BadgeModal from './badges/badgeModal'
import ToggleIcon from "components/toggleIcon";

const HEADING_STYLE = {
  fontSize: "18px",
  lineHeight: "25px",
  fontFamily: "Manrope-Bold",
  marginBottom: "24px",
};

const PHASE_ACCORDION_HEADER_STYLE = {
  ...COMMON_PADDING("0px"),
  height: "54px",
  fontFamily: "Manrope-Bold",
  fontSize: "16px",
  lineHeight: "22px",
};

const PHASE_ACCORDION_CONTENT_STYLE = ({ $theme }) => ({
  paddingLeft: "58px",
  paddingRight: "0px",

  [$theme.mediaQuery.small]: {
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "0"
  },
  [$theme.mediaQuery.medium]: {
    paddingTop: "32px",
    paddingBottom: "24px",
  },
  [$theme.mediaQuery.large]: {
    paddingLeft: "58px",
  }
});

const PROGRESS_SECTION = (theme) => ({ 
  [theme.mediaQuery.small]: {
    width: "70%",
  },
  [theme.mediaQuery.large]: {
    width: "128px",
    marginBottom: "8px",
  }
});

const TRACK_ACCORDION_HEADER_STYLE = {
  ...COMMON_PADDING("0px"),
  height: "54px",
};

const TRACK_ACCORDION_CONTENT_STYLE = ({ $theme }) => ({
  paddingLeft: "0px",
  paddingRight: "0px",

  [$theme.mediaQuery.small]: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  [$theme.mediaQuery.medium]: {
    paddingTop: "20px",
    paddingBottom: "24px",
  },
});

const TRACK_TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-SemiBold",
  fontSize: "16px",
  lineHeight: "22px",
  color: theme?.RAColors?.blue700,
});

const TABLE_ROOT_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
});

const TABLE_HEADER_STYLE = (theme) => ({
  fontFamily: "Manrope-SemiBold",
  fontSize: "14px",
  lineHeight: "19px",
  [theme.mediaQuery.small]: {
    textAlign:"center"
  },
  [theme.mediaQuery.large]: {
    textAlign:"left"
  }
});

const TRACK_ITEM_TITLE_STYLE = {
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "19px",
};

const TRACK_ITEM_CONTENT_STYLE = {
  fontFamily: "Manrope",
  fontSize: "24px",
  lineHeight: "32px",
};

const MODULE_NAME_STYLE = (theme) => ({
  cursor: "pointer",
  color: theme?.RAColors?.blue700,
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "19px",
});

const FIRST_ROW_OVERRIDE_STYLE = ({ $theme }) => ({
  minWidth: '400px',
  [$theme.mediaQuery.small]: {
    display: "grid",
    ...COMMON_PADDING('10px'),
    boxSizing: "border-box",
    border: "none",
    margin: "0",
    minWidth:"auto"
  },
  [$theme.mediaQuery.large]: {
    minWidth: '400px',
    display: "table-cell",
    ...COMMON_PADDING('16px'),
    boxSizing: "inherit",
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    margin: "0",
    borderBottomStyle :"solid",
    borderBottomWidth : "1px"
   
  },
});

const SECOND_ROW_OVERRIDE_STYLE = ({ $theme }) => ({
  borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    borderLeftColor: $theme?.RAColors?.gray200,
    width: '300px',
    minWidth: '200px',
  [$theme.mediaQuery.small]: {
    display: "grid",
    ...COMMON_PADDING('10px'),
    boxSizing: "border-box",
    border:"none",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    minWidth:"auto",
    width: "auto",
    margin: "0 10px 20px 10px" ,
    paddingBottom:"20px"
  },
  [$theme.mediaQuery.large]: {
    display: "table-cell",
    borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    borderLeftColor: $theme?.RAColors?.gray200,
    width: '300px',
    minWidth: '200px',
    ...COMMON_PADDING('16px'),
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    margin: "auto",
    paddingBottom: "auto",
    
  }
});


const TrackProgress = (track) => {
  const [css, theme] = useStyletron();
  const setState = useSetState();
  const history = useHistory();

  const [activeBadge, setActiveBadge] = useState(null);

  let resp = localStorage.getItem("track")
    ? JSON.parse(localStorage.getItem("track"))
    : null;
  const goToAssignment = (assignment) => {
    setState(
      (prev) => ({ ...prev, assignment }),
      () => {
        history.push("/assignment");
      }
    );
  };

  return (
    <Block className={css(COMMON_CARD_STYLE(theme))}>
      <Block className={css(HEADING_STYLE)} data-testid="track-progress-title">
        {Track} {Progress}
      </Block>
      <Block>
        <Accordion
          accordion={false}
          initialState={{
            expanded: (resp?.learning_path?.phase_list || []).map(
              (_, phaseIndexAccordion) => String(phaseIndexAccordion)
            ),
          }}
          overrides={{
            ToggleIcon,
            Content: { style: PHASE_ACCORDION_CONTENT_STYLE },
            Header: { style: PHASE_ACCORDION_HEADER_STYLE },
            PanelContainer: { style: { borderBottomWidth: "0px" } },
          }}
        >
          {resp?.learning_path?.phase_list.map((phase, phaseIndex) => (
            <Panel
              key={phaseIndex}
              title={
                <Block display="flex" gridGap="20px" alignItems="center">
                  <PhaseBadge
                    name={phase.phase_image}
                    isFaded={
                      (phase?.progress_phase?.progress_actual || 0) < 100
                    }
                    showModal={(image) => setActiveBadge(image)}
                  />
                  <Block data-testid="track-progress-phase-name">
                    {phase.phase_name}
                  </Block>
                </Block>
              }
            >
              <Accordion
                accordion={false}
                initialState={{
                  expanded: (phase?.macro_certification_list || []).map(
                    (track, trackIndexAccordion) =>
                      track?.progress_bar?.progress_learning_path
                        ?.progress_actual < 100
                        ? String(trackIndexAccordion)
                        : null
                  ),
                }}
                overrides={{
                  ToggleIcon,
                  Content: { style: TRACK_ACCORDION_CONTENT_STYLE },
                  Header: { style: TRACK_ACCORDION_HEADER_STYLE },
                  PanelContainer: { style: { borderBottomWidth: "0px" } },
                }}
              >
                {phase?.macro_certification_list.map((track, trackIndex) => (
                  <Panel
                    key={trackIndex}
                    title={
                      <Block display="flex" gridGap="20px" alignItems="center">
                        <MacroBadge
                          name={track.macro_certification_image}
                          isFaded={
                            (track?.progress_bar?.progress_learning_path
                              ?.progress_actual || 0) < 100
                          }
                          showModal={(image) => setActiveBadge(image)}
                        />
                        <Block
                          className={css(TRACK_TITLE_STYLE(theme))}
                          data-testid="track-progress-macro-name"
                        >
                          {track.macro_certification_name}
                        </Block>
                      </Block>
                    }
                  >
                    <Block marginBottom="20px">
                      <Block
                        marginBottom="4px"
                        className={css(TRACK_ITEM_TITLE_STYLE)}
                        data-testid="progress_text"
                      >
                        {Progress}
                      </Block>
                      <Block display="flex" gridGap="12px" alignItems="center">
                        <Block
                          as="span"
                          className={css(TRACK_ITEM_CONTENT_STYLE)}
                        >
                          {track?.progress_bar?.progress_learning_path
                            ?.progress_actual || 0}
                          %
                        </Block>
                        <Block width="100px">
                          <ProgressBar
                            value={
                              track?.progress_bar?.progress_learning_path
                                ?.progress_actual || 0
                            }
                          />
                        </Block>
                      </Block>
                    </Block>
                    <TableBuilder
                      data={track.course_list}
                      overrides={{
                        Root: { style: TABLE_ROOT_OVERRIDE_STYLE },
                        TableBodyRow: {
                          component: ({ children }) => <tr>{children}</tr>,
                        },
                      }}
                      emptyMessage="No data"
                      className="track_progress__table"
                    >
                      <TableBuilderColumn
                        header={
                          <Block className={css(TABLE_HEADER_STYLE(theme))}>
                            Quests
                          </Block>
                        }
                        overrides={{
                          TableHeadCell: { style: FIRST_ROW_OVERRIDE_STYLE },
                          TableBodyCell: { style: FIRST_ROW_OVERRIDE_STYLE },
                        }}
                      >
                        {(row) => (
                          <Block
                            display="flex"
                            gridGap="16px"
                            alignItems="center"
                            //className={css(TD_STYLE(theme))}
                          >
                            <MicroBadge
                              width="32px"
                              name={row.course_image}
                              isFaded={
                                (row?.course_progress?.progress_actual || 0) <
                                100
                              }
                              showModal={(image) => setActiveBadge(image)}
                            />
                            <Block
                              onClick={() => goToAssignment(row)}
                              className={css(MODULE_NAME_STYLE(theme))}
                              data-testid="track-progress-micro-name"
                            >
                              {row.course_name}
                            </Block>
                          </Block>
                        )}
                      </TableBuilderColumn>
                      <TableBuilderColumn
                        header={
                          <Block className={css(TABLE_HEADER_STYLE(theme))}>
                            Progress
                          </Block>
                        }
                        overrides={{
                          TableHeadCell: { style: SECOND_ROW_OVERRIDE_STYLE },
                          TableBodyCell: { style: SECOND_ROW_OVERRIDE_STYLE },
                        }}
                      >
                        {(row) => {
                          const { course_progress = {} } = row;
                          return (
                            <Block>
                              <Block className={css(PROGRESS_SECTION(theme))}>
                                <ProgressBar
                                  value={course_progress.progress_actual}
                                  targetValue={
                                    course_progress.progress_expected
                                  }
                                />
                              </Block>
                              {/* {row?.course_name?.substring(0, 2) === "12" ||
                                row?.course_name?.substring(0, 2) === "15" ?
                                <Block as="span">
                                  {"100"}%
                                </Block>
                                : */}
                              <Block>
                                <Block as="span">
                                  {course_progress.progress_actual}%
                                </Block>{" "}
                                {course_progress.progress_behind ||
                                course_progress.progress_exceeded ? (
                                  <Block
                                    as="span"
                                    className={css({
                                      color: course_progress.progress_behind
                                        ? theme.RAColors?.red700
                                        : theme.RAColors?.emerald400,
                                    })}
                                  >
                                    (
                                    {course_progress.progress_behind ||
                                      course_progress.progress_exceeded}
                                    %{" "}
                                    {course_progress.progress_behind
                                      ? Behind
                                      : Exceeds}
                                    )
                                  </Block>
                                ) : null}
                              </Block>
                              {/* } */}
                            </Block>
                          );
                        }}
                      </TableBuilderColumn>
                    </TableBuilder>
                  </Panel>
                ))}
              </Accordion>
            </Panel>
          ))}
        </Accordion>
      </Block>

      {activeBadge && (
        <BadgeModal badge={activeBadge} close={() => setActiveBadge(null)} />
      )}
    </Block>
  );
};

export default TrackProgress;
