import { LEARNER_LIST } from "../apiEndpoint";
import axios from "axios";
import { student_node, studentURL } from "../config";

const LinkforceControllerLearnersList = axios.create({
  baseURL: studentURL,
});

LinkforceControllerLearnersList.defaults.headers.common = {
  ...(LinkforceControllerLearnersList.defaults.headers.common || {}),
  ApiKey: "TESTDEV-0000-1111-2222-333333333333",
};

const studentList = (course_name) =>
  LinkforceControllerLearnersList.get(`${LEARNER_LIST}?`);

export async function replaceValuesInJson() {
  try {
    const response = await axios.get(
      `${student_node}&user_email=${localStorage.getItem("email")}`
    );
    return response; // Assuming the API returns a URL for the image
  } catch (error) {
    console.error("Error fetching new data:", error);
  }
}
export default studentList;
