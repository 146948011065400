import { Header } from "components/Header";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { useMemo, useEffect } from "react";
import { Redirect } from "react-router-dom";
import DrawerRouterContainer from "components/newDrawer";
import Summary from "./components/summary";
import Students from "./components/students";
import Title from "./components/title";
import { useSetState, useTrackedState } from "store/store";
import StudentAPI from "services/requests/student_api";
import useExportPdf from "hooks/useExportPdf";
import getRolesPageVisibility from "../../components/getPageVisibility";
import NestedMenu from "components/nestedMenu";
import { replaceValuesInJson } from "services/requests/learnerList";
import { Learner } from "../../resources/constants/strings";
import { mapStudentData } from "utils/app-utils";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

export default function StudentOverview() {
  const [css, theme] = useStyletron();
  const setState = useSetState();

  const state = useTrackedState();
  const { exportPdf, pdfComponentRef } = useExportPdf(new Date().getFullYear());

  const userType = useMemo(() => {
    const username = localStorage.getItem("username");
    const role = localStorage.getItem("role") || localStorage.getItem("viewAs");

    if ([null, undefined, ""].includes(username)) return null;
    var permittedRoles = getRolesPageVisibility("Learners");
    if (
      permittedRoles.includes(role) &&
      localStorage.getItem("role") !== "LEARNER"
    )
      return "manager";

    return "member";
  }, []);

  useEffect(() => {
    let isUnmounted = false;
    localStorage.setItem("currentPage", "student");
    const fetchStudentOverview = async () => {
      const userEmail = localStorage.getItem("email") || "";

      try {
        setState((prev) => ({ ...prev, isFetching: true }));

        const response = await StudentAPI(
          userEmail,
          localStorage.getItem("role")
        );
        const replaceResponse = await replaceValuesInJson();

        if (response.status !== 200 || isUnmounted) return;
        // let wordpress_data = JSON.parse(localStorage.getItem("wordpress"))
        // const myArrayFiltered = response?.data[0]?.learner_profile_list?.filter((el) => {
        //   return wordpress_data.student_list.some((f) => {
        //     return f.student_email === el.email_id
        //   });
        // });

        const studentOverview = response.data[0]?.students_overview_summary; //wordpress_data?.students_overview_summary
        const learners1 = response.data[0].learner_profile_list; //myArrayFiltered
        // const learners = learners1.map((item2) => {
        //   const item1 = replaceResponse?.data[0]?.learner_profile_list.find(
        //     (item) => item.email_id === item2.email_id
        //   );
        //   return item1
        //     ? {
        //         ...item2,
        //         learner_progress_bar: item1.learner_progress_bar,
        //         learning_path: { ...item1.learning_path },
        //       }
        //     : item2;
        // });
        const learners = mapStudentData(
          learners1,
          replaceResponse?.data[0]?.learner_profile_list
        );

        if (localStorage.getItem("role") === "ADMIN") {
          var learnerCheckEmail = learners.some((element) => {
            if (element.email_id === localStorage.getItem("email")) {
              return true;
            }
          });
          //console.log("learnerCheckEmail",learnerCheckEmail)
          if (!learnerCheckEmail) {
            localStorage.setItem("view_as_learner", learners[0]?.email_id);
          }
        }
        setState((prev) => ({
          ...prev,
          studentOverview,
          learners,
          isFetching: false,
          isFetchTrackData: true,
        }));
      } catch (e) {
        if (isUnmounted) return;
        console.log(e);
        setState((prev) => ({
          ...prev,
          studentOverview: {},
          learners: [],
          isFetching: false,
          isFetchTrackData: false,
        }));
      }
    };

    if (!state.isFetching && !state.learners) fetchStudentOverview();

    return () => {
      isUnmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userType) return <Redirect to="/login" />;

  if (userType === "member") return <Redirect to="/tracksummary" />;

  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Block>
            <Title exportPdf={exportPdf} />
            <Block
              ref={pdfComponentRef}
              display="flex"
              flexDirection="column"
              gridGap={["16px", "16px", "20px"]}
            >
              <Summary />
              <Students />
            </Block>
          </Block>
        </main>
      </DrawerRouterContainer>
    </>
  );
}
