import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";


const engine = new Styletron();
const theme = {
  ...LightTheme,
  RAColors: {
    gray50: "#F9F9F9",
    gray100: "#F3F3F3",
    gray200: "#E0E0E0",
    gray300: "#C2C2C2",
    gray400: "#A3A3A3",
    gray500: "#858585",
    gray600: "#666666",
    gray700: "#525252",
    gray800: "#3D3D3D",
    gray900: "#252525",
    gray950: "#141414",
    purple: "#41407B",
    blue100: "#EAEEFF",
    blue600: "#495EEB",
    blue700: "#3A3FDF",
    blue800: "#3036B2",
    orange100: "#F9F0E2",
    red100: "#FAEBEB",
    red300: "#ECB0B0",
    red600: "#CF3939",
    red700: "#A62E2E",
    red800: "#7C2222",
    emerald50: "#F5FCF8",
    emerald100: "#E2F7E9",
    emerald400: "#3CC069",
    emerald500: "#22A54E",
    black: "#000000",
    green100: "#008000",
    green200: "#228B22",
    yellow200: "#A0A07A",
    yellow600: "#edc12f",
  },
};
ReactDOM.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <App />
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
