import React, { useState } from "react";
import {
  Progress,
  Modules_Behind,
  Modules_Completed,
} from "resources/constants/strings.js";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import ProgressBar from "components/progress";
import TrackBadge from "./badges/trackBadge";
import BadgeModal from './badges/badgeModal'

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  border: `1px solid ${theme.RAColors?.gray200}`,

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});

const TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Bold",
  fontSize: "24px",
  lineHeight: "33px",
  marginBottom: "20px",
  [theme.mediaQuery.small]: {
    fontSize: "18px",
  },
  [theme.mediaQuery.large]: {
    fontSize: "24px",
  },
});

const ITEM_TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Semibold",
  fontSize: "14px",
  lineHeight: "19px",
  marginBottom: "4px",
  color: theme?.RAColors?.gray800,
});

const ITEM_CONTENT_STYLE = (theme) => ({
  fontFamily: "Epilogue-Medium",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme?.RAColors?.gray800,
});

const Summary = ({ track = {} }) => {
  const [css, theme] = useStyletron();

  const [activeBadge, setActiveBadge] = useState(null);

  const { learner_completion_summary, learner_progress_bar } = track ? track : {};

  return (
    <Block
      className={css(BLOCK_STYLE(theme))}
      marginBottom={["16px", "16px", "20px"]}
    >
      <Block
        as="h3"
        className={css(TITLE_STYLE(theme))}
        data-testid="learning_path_name"
      >
        {track?.learning_path_name}
      </Block>
      <Block display="flex" flexWrap="wrap" gridGap="12px" alignItems="center">
        <Block paddingLeft="10px" paddingRight="10px">
          <TrackBadge
            isFaded={
              (learner_progress_bar?.progress_learning_path?.progress_actual ||
                0) < 100
            }
            showModal={(image) => setActiveBadge(image)}
          />
        </Block>
        <Block className={css({ ...BLOCK_STYLE(theme), padding: "16px" })}>
          <Block
            className={css(ITEM_TITLE_STYLE(theme))}
            data-testid="progress_heading_text"
          >
            {Progress}
          </Block>
          <Block display="flex" gridGap="16px" alignItems="center">
            <Block className={css(ITEM_CONTENT_STYLE(theme))}>
              {learner_progress_bar?.progress_learning_path?.progress_actual ||
                0}
              %
            </Block>
            <Block width="100px">
              <ProgressBar
                value={
                  learner_progress_bar?.progress_learning_path
                    ?.progress_actual || 0
                }
                targetValue={
                  learner_progress_bar?.progress_learning_path
                    ?.progress_expected
                }
              />
            </Block>
          </Block>
        </Block>
        {/* <Block
          display="flex"
          flexDirection="row"
          gridGap="20px"
          className={css({ ...BLOCK_STYLE(theme), padding: "16px" })}
        >
          <Block>
            <Block
              data-testid="summary-completed-title"
              className={css(ITEM_TITLE_STYLE(theme))}
            >
              {Modules_Completed}
            </Block>
            <Block className={css(ITEM_CONTENT_STYLE(theme))}>
              {learner_completion_summary?.courses_completed}
              {"/"}
              {learner_completion_summary?.courses_total}
            </Block>
          </Block>
          <Block>
            <Block
              data-testid="summary-behind-title"
              className={css(ITEM_TITLE_STYLE(theme))}
            >
              {Modules_Behind}
            </Block>
            <Block className={css(ITEM_CONTENT_STYLE(theme))}>
              {(learner_completion_summary?.courses_at_risk || 0) +
                (learner_completion_summary?.courses_late || 0)}
            </Block>
          </Block>
        </Block> */}
      </Block>

      {activeBadge && (
        <BadgeModal badge={activeBadge} close={() => setActiveBadge(null)} />
      )}
    </Block>
  );
};

export default Summary;
