let DOMAIN_NAME = process.env.REACT_APP_ENVIRONMENT; 
// console.log("domain:", DOMAIN_NAME);

export const env = DOMAIN_NAME; // dev | uat | prod

export const appVersion = 'v1.0.0';
// dev apis
 export const BaseURL = process.env.REACT_APP_BASEURL;
 export const giniusBaseURL = process.env.REACT_APP_GENIUS_BASEURL;
 export const studentURL = process.env.REACT_APP_STUDENT_URL;
 export const jsURL = process.env.REACT_APP_JUMPSTART_URL;
 export const kahootAuthURL = process.env.REACT_APP_KAHOOT_AUTH_URL;
 export const kahootGameURL = process.env.REACT_APP_KAHOOT_GAMES;
 export const student_node = process.env.REACT_APP_STUDENT_NODE;

// //uat apis
// export const uatBaseURL = 'https://rapidascentapi-stage.azurewebsites.net/api/';
// export const giniusuatBaseURL = 'https://apilinkforce.azurewebsites.net/'


//prod apis
// export const prodBaseURL = 'https://rapidascentapi-stage.azurewebsites.net/api/';
// export const studentURL ='https://controllerlinkforceapp.azurewebsites.net/api/'  //'https://controllerstudentsgo.azurewebsites.net/api';
// export const giniusprodBaseURL = 'https://apilinkforce.azurewebsites.net/'

